<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"/>
<script type="text/javascript"> (function(){ emailjs.init("SADxeLzwIiHEtkccb");})();
</script>
<template>
  <div>
    <br>
    <b-card>
      <h1><b>Welcome Send Mail HN {{ hn }}<b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-xl
        class="ml-2"
        variant="outline-danger"
      >
        <feather-icon
          icon="RotateCcwIcon"
          class="mr-50"
        />
        <span class="align-middle">History</span>
      </b-button></b></h1>
      <br><br>
      <b-form
        ref="form"
        @submit.prevent="sendEmail"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="to email"
              label-for="h-email"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-form-input
                id="h-email"
                v-model="send"
                type="text"
                placeholder="to email"
                name="send"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="ผู้ยืม"
              label-for="h-number"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-form-input
                id="h-number"
                v-model="to_name"
                type="text"
                name="to_name"
                placeholder="ผู้ยืม"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="ชื่อผู้ป่วย"
              label-for="h-number"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-form-input
                id="h-number"
                v-model="patient_name"
                type="text"
                name="patient_name"
                placeholder="ชื่อผู้ป่วย"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="HN"
              label-for="h-number"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-form-input
                id="h-number"
                v-model="HN"
                type="text"
                name="HN"
                placeholder="HN"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Username"
              label-for="h-password"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    v-show="flag === false"
                    variant="outline-primary"
                    @click.prevent="UsernameGenerator"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  id="h-password"
                  v-model="username"
                  type="text"
                  name="username"
                  placeholder=" - Click Button - "
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              label="Username"
              label-for="h-password"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <b-form-input
                id="h-password"
                v-model="username"
                type="text"
                name="username"
                placeholder="username"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group
              label="Password"
              label-for="h-password"
              label-cols-md="4"
              label-cols-xl="2"
            >
              <!-- <b-form-input
                id="h-password"
                v-model="password"
                :type="type"
                name="password"
                placeholder="password"
              /> -->
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="h-password"
                  v-model="password"
                  name="password"
                  :type="passwordFieldType"
                  placeholder="Your Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <br>
          <b-modal
            id="modal-xl"
            ok-only
            ok-title="Accept"
            centered
            size="xl"
            title="Large Modal"
          >
            <b-table
              :fields="fields"
              responsive="sm"
              :items="items"
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template
                #cell(updated_at)="data"
                style="width:1%;"
              >
                {{ data.item.updated_at }}
              </template>
              <template
                #cell(template_params)="data"
                style="width:1%;"
              >
                {{ data.item.id }}
              </template>
            </b-table>
          </b-modal>
          <!-- submit and reset -->
          <b-col offset-md="5">
            <br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              hover
              type="submit"
              variant="primary"
              class="mr-1"
            >
              <feather-icon icon="MailIcon" /> SEND EMAIL
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-card>
  </div>
</template>
<script>
import { VBModal, BButton, BModal, BTable,  BFormFile } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import emailjs from '@emailjs/browser';
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BTable,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
    props: {
    hn: {
      type: String,
      default: () => '00000',
    },
  },
  data() {
    return {
      imageUrls: [],
      GetImage: [],
      MRDoc: [],
      send: '@gmail.com',
      to_name: '',
      patient_name: '',
      HN: '',
      password: '',
      items: [],
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        // A column that needs custom formatting
        { key: 'updated_at', label: 'updated_at' },
        // A regular column
        { key: 'template_params', label: 'template_params' },
      ],
      username: '',
      flag: false,
      id_Bset: '',
      patientInfoByName:[],
      patientInfoByHN:[],
      setRequestStatus: null,
      ReqCode:null,
      // type: 'password',
      // btnText: 'Show Password'
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {},
  async mounted() {
    await this.$http({
      url: 'https://api.emailjs.com/api/v1.1/history?user_id=SADxeLzwIiHEtkccb&accessToken=dkDqgQ6rqnfxHU1FWGoHA&page=1&count=50',
      method: 'get',
    }).then((response) => {
      this.items = response.data.rows
      console.log('🚀 ~ file: testMail.vue ~ line 235 ~ mounted ~ this.items', response.data.rows)
    })
    this.GetPatientInfoByHN()
    this.HN = this.hn
  },
  methods: {
    async SetRequest (){
      await this.$http({
          url: 'api/HIE/setRequest',
          method: 'post',
          data: {
            uid: this.id_Bset,
            reason: `เหตุผล เพื่อการรักษา, ขอเอกสารไปดูที่ ข้างนอกโรงพยาบาล`,
            createby: this.username,
            hns: [this.hn],
          },
        }).then(response => {
          this.setRequestStatus = response.data
          console.log('setRequestStatus', response.data)
        })
    },
    ApproveFlag(code) {
      this.$http({
        url: 'api/user/SaveApproveFlag',
        method: 'POST',
        data: {
          Code: code,
        },
      }).then(response => {
          console.log('ApproveFlag', response.data)
          //Set Approve Complete
      })
    },
     async SetReqCode(uid, hnCode) {
      await this.$http({
        url: `http://192.168.100.141/api/HIE/GetHieRequestDetailByUID/${uid}/${hnCode}`,
        method: 'get',
      }).then(response => {
          console.log('SetReqCode', response.data[0].code)
          this.ReqCode = response.data[0].code
      })
      await this.ApproveFlag(this.ReqCode)
    },
    async sendEmail (){
      await this.SetRequest()
      await this.SetReqCode(this.id_Bset,this.hn)
      await this.confirmText()
    },
    confirmText() {
      this.$swal({
        title: 'คุณแน่ใจ?',
        text: 'ว่าต้องการส่ง email',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ต้องการ!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'กำลังทำการส่ง email.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
            console.log("this.$refs.form", this.$refs.form)
            const serviceID = 'default_service';
            const templateID = 'template_ek3ul6p';
            const PUBLIC_KEY = 'SADxeLzwIiHEtkccb';
            emailjs.sendForm(serviceID, templateID, this.$refs.form,PUBLIC_KEY)
              .then((result) => {
                console.log('SUCCESS!', result.text);
              }, (err) => {
                btn.value = 'Send Email';
                console.log('FAILED...', error.text);
              });
        }
      })
    },
    async UsernameGenerator() {
      await this.$http({
        url: 'api/User/newUser',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.username = response.data.username
        this.flag = true
        this.id_Bset = response.data.id
        if(response.data.length != 0){
          this.addUser(response.data.id, response.data.username)
        }
      })
    },
    async addUser(id, username) {
      await this.$http({
        url: 'api/User/UpdateUser',
        method: 'post',
        data: {
          ID: id,
          Type: '7',
          Title: this.patientInfoByHN[0].title,
          FName: this.patientInfoByHN[0].fName,
          LName: this.patientInfoByHN[0].lName,
          Address: '-',
          Phone: '-',
          Email: this.send,
        },
      }).then(response => {
        this.addUser_status = response.data
      })
      await this.$http({
        url: 'api/User/UpdatePassword',
        method: 'post',
        data: {
          ID: id,
          Username: username,
          Password: this.password,
        },
      })
      // await this.SetRequest()
      // await this.SetReqCode(id,this.hn)
    },
    GetPatientInfoByName(Info) {
      this.$http({
        url: `http://192.168.100.141/api/HIE/GetPatientInfo/${Info.fName}/${Info.lName}/-`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.patientInfoByName = response.data
        console.log('patientInfoByName', response.data)
      })
    },
    async GetPatientInfoByHN() {
      const hn = this.hn.padStart(7, '0')
      await this.$http({
        url: `/api/HIE/SearchPatientByHN/${hn}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.patientInfoByHN = response.data
        console.log('patientInfoByHN ', response.data)
        this.password = response.data[0].id
        this.to_name = `${response.data[0].title} ${response.data[0].fName} ${response.data[0].lName}`
        this.patient_name = `${response.data[0].title} ${response.data[0].fName} ${response.data[0].lName}`
      })
      await this.GetPatientInfoByName(this.patientInfoByHN[0])
    },
  },
}
</script>
<style lang="scss">
@media print {
  [dir] .vx-card.card-border {
    border: 1px solid #ffffff;
  }
  .vx-card__body {
    max-width: 100%;
  }
  .vs-sidebar {
    max-width: 1% !important;
    visibility: hidden;
  }
  .the-footer {
    max-width: 5% !important;
    visibility: hidden;
  }
  .vx-navbar-wrapper {
    max-width: 5% !important;
    visibility: hidden;
  }
  .mb-base {
    display: none !important;
  }
  .vx-card__collapsible-content {
    max-width: 100% !important;
    // display: none !important;
  }
  .vx-card {
    display: none !important;
  }
  .vs-button {
    display: none !important;
  }
}

@page {
  size: A4;
  margin-top: -4.2cm;
  margin-left: -2.8cm;
  margin-bottom: -50cm;
}
</style>
